@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Sarabun:wght@400;800&display=swap);
/* @import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css'; */
/* @import '~video.js/dist/video-js.css'; */
/* @import '~swiper/swiper-bundle.min.css'; */

body {
  font-family: "Sarabun", Helvetica, sans-serif;
  margin: 0;
}

